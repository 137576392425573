<template>
  <header>
    <div class="linha">
      <div class="coluna">
        <nav class="jm jm-menu nav-maior none" id="menu">
          <div class="separador">
            <a
              v-for="modulo in menus"
              v-bind:key="modulo.id"
              :href="modulo.URL"
              :class="moduloAtivo == modulo.nome ? 'ativo' : ''"
              >{{ modulo.nome }}</a
            >
          </div>
        </nav>
        <a
          :href="linkPortal"
          class="logo logo-menor"
          >Thalamus</a
        >
        <a
          href="#"
          class="icone-avancar negrito com-texto"
          onclick="document.getElementById('menu').classList.toggle('none');this.classList.toggle('ativo');return false"
        >
        {{ moduloAtivo }}</a
        >
      </div>

      <div class="coluna">
        <a
          href="#"
          class="avatar direita"
          onclick="document.getElementById('usuario').classList.toggle('none');this.classList.toggle('ativo');return false"
          :title="user.nome"
          ><img
            src="https://cdn-icons-png.flaticon.com/128/709/709722.png"
            :class="theme == 'dark' ? 'white-profile' : 'none'"
          />
        </a>

        <nav class="jm jm-avatar none" id="usuario">
          <div class="separador margem alinha-centro negrito">
            {{ user.nome }}
          </div>
          <div class="separador">
            <a
              href="#"
              :class="theme == 'dark' ? 'ativo' : ''"
              onclick="document.documentElement.classList.toggle('dark');this.classList.toggle('ativo');return false"
              @click="setTheme()"
              ><span class="direita toggle"></span>Tema escuro</a
            >
          </div>
          <div class="separador bg-cinza">
            <!-- <a href="#">Ajuda</a> -->
            <!-- <a href="#">Alterar senha</a> -->
            <a
              :href="linkPortal + 'login'"
              @click="logout()"
              >Sair</a
            >
          </div>
        </nav>
        <!-- <a
          href="#"
          class="icone-notif direita"
          title="Notificações"
          onclick="document.getElementById('notificacoes').classList.toggle('none');this.classList.toggle('ativo');return false"
        ></a> -->

        <a href="#" class="icone-notif direita" title="Notificações"></a>

        <!-- <nav class="jm jm-notif none" id="notificacoes">
          <div class="separador">
            <a href="#"
                  ><span class="fonte-fraca fonte-menor direita">12min</span>Título
                  da notificação</a
                >
                <a href="#"
                  ><span class="fonte-fraca fonte-menor direita">2h</span>Título da
                  notificação</a
                >
          </div>

        </nav> -->
      </div>
    </div>
  </header>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import headerMenuService from "@/services/headerMenuService";
import { ref } from "vue";
import moduloService from "@/services/headerMenuService";

export default {
  data() {
    return {
      user: null,
      theme: null,
      moduloAtivo: "Monitoramento",
      menus: null,
    };
  },
  async created() {
    this.user = sso.getUsuarioLogado();
    this.theme = localStorage.getItem("tema");
    if (this.theme == null) {
      localStorage.setItem("tema", "light");
    } else {
      document.documentElement.classList.toggle(this.theme);
    }

    this.menus = await moduloService.buscarModulos()
  },
  setup() {
    let modulosList = ref(new Array());
    let linkPortal = ref();

    const carregarModulos = () => {
      headerMenuService.buscarModulos().then((data) => {
        modulosList.value = data;
      });
      linkPortal.value = headerMenuService.linkPortal()
    };

    carregarModulos();

    return {
      carregarModulos,
      modulosList,
      linkPortal
    };
  },
  methods: {
    logout() {
      sso.logout();
    },

    setTheme() {
      if (this.theme == "light") {
        localStorage.setItem("tema", "dark");
        this.theme = "dark";
      } else {
        localStorage.setItem("tema", "light");
        this.theme = "light";
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  border: 2px solid var(--cor-primaria);
  margin-top: 3px;
}

.white-profile {
  filter: invert(100%);
}

a > img {
  height: 24px;
  width: 24px;
}
</style>
