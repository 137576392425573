<template>
  <section>

    <div class="titulo">
      <div class="margem container">
        <!-- Download de relatório -->
        <div title="Clique para baixar o aplicativo" class="m-icone direita">
          <a
            href="https://drive.google.com/file/d/1wuaCaIZalghqvqPo3csqEiQH437qFZee/view?usp=sharing"
            target="_blank"
          >
            <div class="icone-download-apk" style="transform: scale(1.5)"></div>
          </a>
        </div>
        <h2>Monitor de {{ isTabActive('tab1') ? 'bateria' : 'acelerômetro' }}</h2>

      </div>
    </div>

    <div class="margem">
      <div class="margem container bloco">
        <div class="linha alinha-v">
          <div class="m-icone">
            <a href="/" class="icone-arrow-back"></a>
          </div>
          <div class="tags">
            <a
              class="titulo"
              :class="isTabActive('tab1') ? 'ativo' : ''"
              @click="changeTab('tab1')"
              >Bateria</a
            >
            <a
              class="titulo"
              :class="isTabActive('tab2') ? 'ativo' : ''"
              @click="changeTab('tab2')"
              >Acelerômetro</a
            >
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              place-items: end;
              margin-left: auto;
            "
          >
            <div class="fonte-fraca">Dispositivo</div>
            <h3>id {{ device.id }} - {{ device.brand }} {{ device.model }}</h3>
          </div>
        </div>
        <div v-show="isTabActive('tab1')">
          <BateriaView />
        </div>
        <div v-show="isTabActive('tab2')">
          <AcelerometroView />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AcelerometroView from "./AcelerometroView.vue";
import BateriaView from "./BateriaView.vue";

export default {
  data() {
    return {
      activeTab: "tab1",
      device: "",
    };
  },
  components: {
    AcelerometroView,
    BateriaView,
  },
  methods: {
    isTabActive(tab) {
      if (tab == this.activeTab) {
        return true;
      } else {
        return false;
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
  created() {
    this.device = JSON.parse(sessionStorage.getItem("dispositivoSelecionado"));
  },
};
</script>

<style scoped>
.tags {
  cursor: pointer;
}
</style>
