/* eslint-disable */
import api from "@/services/api";
import { BatteryDataModel } from "../models/BatteryDataModel";
export default class BatteryDataSource {
  async getBatteryData(deviceId, dtParaBusca) {
    const dataList = [];

    try {
      const response = await api.post(`monitor-bateria/${deviceId}`, {
        dtInicio: dtParaBusca.dtStart + " " + dtParaBusca.hourStart,
        dtFim: dtParaBusca.dtEnd + " " + dtParaBusca.hourEnd,
      });

      console.log({
        dtInicio: dtParaBusca.dtStart + " " + dtParaBusca.hourStart,
        dtFim: dtParaBusca.dtEnd + " " + dtParaBusca.hourEnd,
      })
      response.data.forEach(addToList);
    } catch {
      return [];
    }

    function addToList(data) {
      dataList.push(BatteryDataModel.fromJson(data));
    }
    return dataList;
  }

  async downloadData(deviceId, dtParaBusca) {
    const response = await api.post(
      "relatorio/monitorar-bateria",
      {
        dispositivo_id: deviceId,
        dtInicio: dtParaBusca.dtStart + " " + dtParaBusca.hourStart,
        dtFim: dtParaBusca.dtEnd + " " + dtParaBusca.hourEnd,
      },
      { responseType: "arraybuffer" }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }

  mockData = [
    {
      bateriaBaixa: 0,
      bateriaEscala: 100,
      bateriaNivel: 64,
      bateriaPresente: 1,
      bateriaSaude_cod: 2,
      bateriaSaude_nome: "Good",
      bateriaStatus_cod: 3,
      bateriaStatus_nome: "Discharging",
      carregamentoTipo_cod: 0,
      carregamentoTipo_nome: "Unknown",
      carregando: 0,
      created_at: "2024-04-17T18:14:01.000000Z",
      deleted_at: null,
      dispositivo_id: 8,
      energiaAtual_ma: 790100,
      energiaAtual_nw: 1841830,
      energiaMedia_ma: -2147483648,
      id: 2570,
      temperatura: 286,
      tempoParaCarga: -1,
      updated_at: "2024-04-17T18:14:01.000000Z",
      voltagem: 3964,
    },
    {
      bateriaBaixa: 0,
      bateriaEscala: 100,
      bateriaNivel: 63,
      bateriaPresente: 1,
      bateriaSaude_cod: 2,
      bateriaSaude_nome: "Good",
      bateriaStatus_cod: 3,
      bateriaStatus_nome: "Discharging",
      carregamentoTipo_cod: 0,
      carregamentoTipo_nome: "Unknown",
      carregando: 0,
      created_at: "2024-04-17T18:15:01.000000Z",
      deleted_at: null,
      dispositivo_id: 8,
      energiaAtual_ma: 155487,
      energiaAtual_nw: 1814340,
      energiaMedia_ma: -2147483648,
      id: 2571,
      temperatura: 296,
      tempoParaCarga: -1,
      updated_at: "2024-04-17T18:15:01.000000Z",
      voltagem: 3906,
    },
    {
      bateriaBaixa: 0,
      bateriaEscala: 100,
      bateriaNivel: 64,
      bateriaPresente: 1,
      bateriaSaude_cod: 2,
      bateriaSaude_nome: "Good",
      bateriaStatus_cod: 3,
      bateriaStatus_nome: "Discharging",
      carregamentoTipo_cod: 0,
      carregamentoTipo_nome: "Unknown",
      carregando: 0,
      created_at: "2024-04-17T18:14:01.000000Z",
      deleted_at: null,
      dispositivo_id: 8,
      energiaAtual_ma: 790100,
      energiaAtual_nw: 1841830,
      energiaMedia_ma: -2147483648,
      id: 2570,
      temperatura: 286,
      tempoParaCarga: -1,
      updated_at: "2024-04-17T18:14:01.000000Z",
      voltagem: 3964,
    },
    {
      bateriaBaixa: 0,
      bateriaEscala: 100,
      bateriaNivel: 65,
      bateriaPresente: 1,
      bateriaSaude_cod: 2,
      bateriaSaude_nome: "Good",
      bateriaStatus_cod: 3,
      bateriaStatus_nome: "Discharging",
      carregamentoTipo_cod: 0,
      carregamentoTipo_nome: "Unknown",
      carregando: 0,
      created_at: "2024-04-17T18:15:01.000000Z",
      deleted_at: null,
      dispositivo_id: 8,
      energiaAtual_ma: 655487,
      energiaAtual_nw: 1814340,
      energiaMedia_ma: -2147483648,
      id: 2571,
      temperatura: 296,
      tempoParaCarga: -1,
      updated_at: "2024-04-17T18:15:01.000000Z",
      voltagem: 3956,
    },
  ];
}
