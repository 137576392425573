import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import PainelDispositivoView from "@/views/PainelDispositivoView.vue"
import { sso } from "roboflex-thalamus-sso-lib";

export class RoutesName {
  static home = "home";
  static painelDispositivo = "painelDispositivo";
}


function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

const routes = [
  {
    path: "/",
    name: RoutesName.home,
    component: HomeView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/painelDispositivo",
    name: RoutesName.painelDispositivo,
    component: PainelDispositivoView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/login",
    redirect: "http://portal.thalamus.ind.br/#/login",
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});

export default router;
