<template>
  <HeaderComponent />

  <main id="main">
    <MenuLateral />
    <RouterView />
  </main>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import MenuLateral from "@/components/MenuLateral.vue";

export default {
  components: {
    HeaderComponent,
    MenuLateral,
  },
};
</script>

<style>
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--cor-separador);
  border-radius: 20px;
}
</style>
