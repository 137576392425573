<template>
  <section>
    <div class="titulo">
      <div class="margem container">
        <!-- Download de relatório -->
        <div title="Clique para baixar o aplicativo" class="m-icone direita">
          <a
            href="https://drive.google.com/file/d/1wuaCaIZalghqvqPo3csqEiQH437qFZee/view?usp=sharing"
            target="_blank"
          >
            <div class="icone-download-apk" style="transform: scale(1.5)"></div>
          </a>
        </div>
        <h2>Monitor de dispositivos</h2>

      </div>
    </div>

    <div class="margem">
      <div class="margem container bloco">
        <table class="tabela">
          <tr>
            <th>Id</th>
            <th>Código do dispositivo</th>
            <th>Marca</th>
            <th>Modelo</th>
          </tr>
          <tr v-for="element in dats" :key="element" v-on:click="pushTo(element)">
            <td>{{ element.id }}</td>
            <td>{{ element.deviceCode }}</td>
            <td>{{ element.brand }}</td>
            <td>{{ element.model }}</td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import DeviceDataSource from "@/data/datasource/DeviceDataSource";
import { RoutesName } from "@/router";

const repo = new DeviceDataSource();

export default {
  data() {
    return {
      dats: [],
      columns: [],
    };
  },
  methods: {
    getData: async function () {
      this.dats = await repo.getDevices();
      this.columns = Object.keys(this.dats[0] ?? "");
    },

    /**
     * @param {DeviceModel} item
     */

    pushTo: function pushToNewPage(item) {
      sessionStorage.setItem("dispositivoSelecionado", JSON.stringify(item));
      this.$router.push(RoutesName.painelDispositivo);
    },
  },
  created() {
    this.getData();
  },
};
</script>
